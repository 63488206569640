/* eslint-disable */
import { create, find } from 'lodash';
import { get, post, upload } from './util/request'
import Vue from 'vue';
import { normalizeUnits } from 'moment';
import {
    getToken,
    setToken, setRefreshToken, setUserInfo, getUserInfo, removeAll, setWXState,
    getWXState, getRandomString
} from './util/storage';
import QRCode from 'qrcodejs2'
import path from './util/baseUrl'
import { Message } from 'element-ui';
import { refreshUserInfo } from "../tools/public.js"
import { scanLogin } from "../tools/util/api.js"

//banner列表
function getAdvertList(data) {
    return post('/api/advert/list', data)
}

//登录
function toLogin(data) {
    return post('/api/sysAuth/login', data)
}

//获取登录用户信息
function userInfo(data) {
    return get('/api/sysUser/baseInfo', data)
}

//退出登录
function logout(data) {
    return post('/api/sysAuth/logout', data)
}
//注册
function registerUser(data) {
    return post('/api/sysUser/registerUser', data)
}
//发送用户注册短信
function sendRegisterVerifCode(data) {
    return post('/api/sysUser/sendRegisterVerifCode', data)
}

//找回密码-发送找回密码短信
function sendFindPwdVerifCode(data) {
    return post('/api/sysUser/sendFindPwdVerifCode', data)
}
//找回密码
function changePwdByVerifyCode(data) {
    return post('/api/sysUser/changePwdByVerifyCode', data)
}

//登录  获取验证码
function loginVerifyCode(data) {
    return post('/api/sysuser/SendUserLoginVerifCode', data)
}

//验证码登录
function verifyCodeLogin(data) {
    return post('/api/sysauth/LoginByVerifyCode', data)
}

//关于瓦特交易课
export function getAboutUs(data) {
    return post('/api/content/getAboutUs', data)
}

function showNotification(message, type = 'info') {
    // 创建一个新的 div 元素
    const notification = document.createElement('div');
    notification.className = `notification ${type}`;
    notification.textContent = message;

    notification.style.opacity = '1';
    notification.style.transition = 'opacity 0.3s ease-in-out';

    // 添加到页面中
    document.body.appendChild(notification);

    setTimeout(() => {
        notification.style.opacity = '0';
        setTimeout(() => {
            document.body.removeChild(notification);
        }, 300);
    }, 3000);
}

const LoginPlugin = {
    install(Vue, options) {
        const globalState = Vue.observable({
            isShow: false,
            loginType: 1,  //1 密码登录，2 验证码登录，3 微信扫码登录 4 找回密码  5 注册
            bannerList: [],

            qrcode: null,
            isQCodeInvalid: false,
            state: null,

            agreeterms: false, //是否同意协议
            phone: '',
            password: '',
            code: '',

            codeTimeLogin: 60,
            codeTextLogin: '获取验证码',

            phoneFind: '',  //找回密码手机号
            codeFind: '',   //找回密码验证码
            passwordFind: '',
            codeTimeFind: 60,
            codeTextFind: '获取验证码',

            phoneRegist: '',  //找回密码手机号
            codeRegist: '',   //找回密码验证码
            passwordRegist: '',
            codeRegistTime: 60,
            codeRegistText: '获取验证码',

            userInfo: null,

            showOverlay: false, // 控制悬浮层的显示隐藏
            globalDialog: {
                showDialog: false,
                showBottom: false,
                title: '关于瓦特交易课',
                contentHtml: ''
            },
            qrCodeBannerHover: false,
            intervalScan: null,
        });
        // 添加全局mixin，使得所有组件实例都能访问这个响应式变量
        Vue.mixin({
            data() {
                return {
                    codeFixdText: '获取验证码',   //固定显示的文本
                    codeTimeCount: 60,          // 倒计时秒数
                    global: globalState
                };
            },
            created() {
                this.global.isShow = false;

                this.global.userInfo = this.GetUserInfo();
                // console.log(this.global.userInfo)
                // 获取屏幕宽度  
                // var screenWidth = window.innerWidth;
                // console.log(screenWidth, "========获取宽度")
                // if (screenWidth < 820) {
                //     window.location.href = "https://m.1848q.com"
                // }
            },
            methods: {
                //显示全员弹出层
                showGlobalDialog() {
                    document.body.style.overflow = 'hidden';
                    this.global.globalDialog.showDialog = true;
                    getAboutUs({}).then(res => {
                        if (res.code == 200) {
                            this.global.globalDialog.contentHtml = res.result.contentDetail
                        }
                    })
                },
                //关闭全局对话框
                closeGlobalDialog() {
                    document.body.style.overflow = '';
                    this.global.globalDialog.showDialog = false;
                },
                //刷新二维码
                toRefreshQcodeClick() {
                    document.getElementById('qrcode').innerHTML = ''
                    this.getWxQCode()
                    this.isQCodeInvalid = false
                },
                getWxQCode() {
                    var that = this
                    const url = path.WX_LOGIN_DOMAIN + "/wxAuthLogin.html"
                    if (!this.global.state) {
                        this.global.state = getRandomString()
                        setWXState(this.global.state)
                    }
                    var appId = path.WX_APPID
                    let tourl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_userinfo&state=${this.global.state}#wechat_redire`
                    console.log(tourl)
                    setTimeout(() => {
                        var qrcode = document.getElementById("qrcode")
                        if (qrcode) {
                            qrcode.innerHTML = ''
                        }
                        that.global.qrcode = new QRCode(qrcode, {
                            text: tourl,
                            width: 180,
                            height: 180,
                            colorDark: "#333333", //二维码颜色
                            colorLight: "#ffffff", //二维码背景色
                            correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
                        })
                        that.global.qrcode._el.title = "微信扫码授权登录"
                        that.toScanLogin()
                    }, 500)
                },
                toScanLogin() {
                    var that = this
                    if (!this.intervalScan) {
                        that.intervalScan = setInterval(async () => {
                            scanLogin({ state: getWXState() }).then(res => {
                                if (res.code == 200) {
                                    if (res.result) {
                                        that.toClearInterval()
                                        setToken(res.result.accessToken)
                                        setRefreshToken(res.result.refreshToken)
                                        refreshUserInfo()
                                    }
                                } else if (res.code == 2002) {
                                    Message.error(res.message)
                                    that.toClearInterval()
                                    if (document.getElementById('qrcode')) {
                                        document.getElementById('qrcode').innerHTML = ''
                                    }
                                }
                            })
                        }, 3000);
                    }
                },
                //关闭登录取消scanlogin
                toClearInterval() {
                    if (this.intervalScan) {
                        clearInterval(this.intervalScan)
                        this.intervalScan = null
                    }
                },
                //通用方法，获取用户信息
                GetUserInfo() {
                    var json = getUserInfo();
                    if (!json) return null;
                    return JSON.parse(json || '');
                },
                showUserName() {
                    if (!this.global.userInfo) return '';
                    return this.global.userInfo.realName || this.global.userInfo.nickName || this.global.userInfo.account;
                },
                //显示登录模态对话框
                showLoginModal() {
                    this.global.isShow = true;
                    this.loadBannder();
                },
                switchLoginType(vl) {
                    if (document.getElementById('qrcode')) {
                        document.getElementById('qrcode').innerHTML = ''
                    }
                    this.global.loginType = vl;
                    this.global.agreeterms = false;
                    if (vl == 3) {
                        this.global.agreeterms = true;
                    }
                    // else if(vl == 5){
                    //     this.global.codeRegist = null
                    //     this.global.passwordRegist = null
                    // }
                },
                loadBannder() {
                    getAdvertList({ "groupKey": "Web", "typeCode": 4 }).then(res => {
                        if (res.code == 200) {
                            this.global.bannerList = res.result;
                        }
                    })
                },

                //常规方式登录校验
                normalValidate() {
                    if (this.global.loginType == 1) {
                        if (!this.global.phone) {
                            this._alert("请输入手机号");
                            return false;
                        }
                        if (!this.global.password) {
                            this._alert("请输入密码");
                            return false;
                        }
                    }
                    if (this.global.loginType == 2) {
                        if (!this.global.phone) {
                            this._alert("请输入手机号");
                            return false;
                        }
                        if (!this.global.code) {
                            this._alert("请输入验证码");
                            return false;
                        }
                    }
                    return true;
                },
                _alert(msg) {
                    showNotification(msg, 'warning')
                },
                //原生提示框
                NativeNotify(msg, type) {
                    //info, warning ,error 
                    showNotification(msg, type || 'info')
                },
                //常规方式登录
                normalMethodLogin() {
                    if (!this.global.agreeterms) {
                        this._alert("请勾选同意服务条款");
                        return;
                    }
                    let ckResult = this.normalValidate();
                    if (!ckResult) {
                        return
                    }

                    if (this.global.loginType == 1) {
                        let password = this.global.password;
                        toLogin({
                            "account": this.global.phone,
                            "password": password,
                            "code": ''
                        }).then(res => {
                            if (res.code == 200) {
                                if (res.result) {
                                    this._loginSuccessed(res.result);
                                }
                            }
                        })
                    }

                    //验证码登录
                    if (this.global.loginType == 2) {
                        verifyCodeLogin({ account: this.global.phone, code: this.global.code }).then(res => {
                            if (res.code == 200) {
                                if (res.result) {
                                    this._loginSuccessed(res.result);
                                }
                            }
                        })
                    }

                },

                //手机验证码登录状态控制
                smsCodeLoginState() {
                    if (!this.global.phone) {
                        this._alert("请输入手机号");
                        return false;
                    }

                    if (this.global.codeTimeLogin != this.codeTimeCount) {
                        console.log('60s稍后再试');
                        return;
                    }
                    //验证码登录
                    loginVerifyCode({ "account": this.global.phone }).then(res => {
                        if (res.code == 200) {
                            console.log('验证码已发送，注意查收');
                            let idx = setInterval(() => {
                                this.global.codeTimeLogin = this.global.codeTimeLogin - 1;

                                if (this.global.codeTimeLogin <= 0) {
                                    this.global.codeTextLogin = this.codeFixdText;
                                    this.global.codeTimeLogin = this.codeTimeCount;
                                    clearInterval(idx);
                                    return;
                                }


                                this.global.codeTextLogin = this.global.codeTimeLogin + '秒'
                            }, 1000);
                        } else {
                            this._alert(res.message);
                        }
                    })
                },

                //找回密码---验证码
                findPwdSMSState() {
                    if (!this.global.phoneFind) {
                        this._alert("请输入手机号");
                        return false;
                    }

                    if (this.global.codeTimeFind != this.codeTimeCount) {
                        console.log('60s稍后再试');
                        return;
                    }

                    sendFindPwdVerifCode({ "account": this.global.phoneFind }).then(res => {
                        if (res.code == 200) {
                            console.log('验证码已发送，注意查收');
                            let idx = setInterval(() => {
                                this.global.codeTimeFind = this.global.codeTimeFind - 1;

                                if (this.global.codeTimeFind <= 0) {
                                    this.global.codeTextFind = this.codeFixdText;
                                    this.global.codeTimeFind = this.codeTimeCount;
                                    clearInterval(idx);
                                    return;
                                }

                                this.global.codeTextFind = this.global.codeTimeFind + '秒'
                            }, 1000);
                        } else {
                            this._alert(res.message);
                        }
                    })
                },

                //找回密码
                findPassword() {
                    if (!this.global.phoneFind) {
                        this._alert("请输入手机号");
                        return false;
                    }
                    if (!this.global.codeFind) {
                        this._alert("请输入验证码");
                        return false;
                    }
                    if (!this.global.passwordFind) {
                        this._alert("请输入新的密码");
                        return false;
                    }

                    changePwdByVerifyCode({
                        account: this.global.phoneFind,
                        newPassword: this.global.passwordFind,
                        code: this.global.codeFind
                    }).then(res => {
                        if (res.code == 200) {

                            this.global.loginType = 1;
                            this.global.phone = this.global.phoneFind;

                            this._alert('密码已重置，请重新登录');
                        }
                    })

                },

                //用户注册---手机验证码
                smsCodeRegistUI() {
                    if (!this.global.phoneRegist) {
                        this._alert("请输入手机号");
                        return false;
                    }

                    if (this.global.codeRegistTime != this.codeTimeCount) {
                        console.log('60s稍后再试');
                        return;
                    }

                    sendRegisterVerifCode({ "account": this.global.phoneRegist }).then(res => {
                        if (res.code == 200) {
                            console.log('验证码已发送，注意查收');
                            let idx = setInterval(() => {
                                this.global.codeRegistTime = this.global.codeRegistTime - 1;

                                if (this.global.codeRegistTime <= 0) {
                                    this.global.codeRegistText = this.codeFixdText;
                                    this.global.codeRegistTime = this.codeTimeCount;
                                    clearInterval(idx);
                                    return;
                                }

                                this.global.codeRegistText = this.global.codeRegistTime + '秒'
                            }, 1000);
                        }
                    })
                },

                //用户注册
                userRegister() {
                    if (!this.global.agreeterms) {
                        this._alert("请勾选同意服务条款");
                        return;
                    }

                    if (!this.global.phoneRegist) {
                        this._alert("请输入手机号");
                        return false;
                    }
                    if (!this.global.passwordRegist) {
                        this._alert("请输入密码");
                        return false;
                    }
                    if (!this.global.codeRegist) {
                        this._alert("请输入验证码");
                        return false;
                    }

                    registerUser({
                        account: this.global.phoneRegist,
                        password: this.global.passwordRegist,
                        code: this.global.codeRegist,
                        regAppCode: '1' //web:1  APP:2  H5:3  manage:4
                    }).then(res => {
                        if (res.code == 200) {
                            if (res.result) {
                                this._alert('注册成功')

                                this._pwdLogin({
                                    phone: this.global.phoneRegist,
                                    password: this.global.passwordRegist,
                                });
                            }
                        }
                    })
                },
                _pwdLogin(form) {
                    this.global.loginType = 1
                    this.global.password = form.password;
                    this.global.phone = form.phone;
                    this.global.agreeterms = true;
                    this.normalMethodLogin();
                },
                _loginSuccessed(tokenReustl) {
                    this.global.isShow = false;
                    setToken(tokenReustl.accessToken)
                    setRefreshToken(tokenReustl.refreshToken)
                    userInfo({}).then(res => {
                        if (res.code == 200) {
                            console.log('获取用户信息成功-666');
                            this.global.userInfo = res.result;
                            setUserInfo(JSON.stringify(res.result))

                            this._alert('登录成功')
                            location.reload();
                        }
                    })
                },
                //重新加载用户信息
                reloadUserInfo(isRefreshPage) {
                    userInfo({}).then(res => {
                        if (res.code == 200) {
                            console.log('获取用户信息成功-8888');
                            this.global.userInfo = res.result;
                            setUserInfo(JSON.stringify(res.result))
                            if (isRefreshPage)
                                location.reload();
                        }
                    })
                },
                hideOverlay(event) {
                    // 当鼠标离开按钮和悬浮层时才隐藏悬浮层
                    if (!event.relatedTarget || !event.currentTarget.contains(event.relatedTarget)) {
                        this.global.showOverlay = false;
                    }
                },
                getExpireTime(value) {
                    var date = new Date(value);
                    var y = date.getFullYear(),
                        m = date.getMonth() + 1,
                        d = date.getDate(),
                        h = date.getHours(),
                        i = date.getMinutes()
                    if (m < 10) { m = '0' + m; }
                    if (d < 10) { d = '0' + d; }
                    if (h < 10) { h = '0' + h; }
                    if (i < 10) { i = '0' + i; }
                    var t = y + '/' + m + '/' + d;
                    return t;
                },

                toLogoutClick() {
                    logout({}).then(res => {
                        this.global.userInfo = null;
                        removeAll()
                        if (location.pathname.indexOf('mine.html') > -1) {
                            location.href = 'index.html';
                        } else {
                            location.reload();
                        }
                        //this._alert('退出登录');
                    }).catch(err => {
                        this.global.userInfo = null;
                        removeAll();
                        location.reload();
                        //this._alert('退出登录');
                    })
                },

                winOpen(url) {
                    location.href = url;
                },
                openVip() {
                    // if (location.pathname.indexOf('vip.html') > -1) return;
                    window.open('/vip.html', "_top");
                },
                riskDeclare() {
                    window.open('/riskdisclaimer.html');
                }
            }
        });

        // 添加全局指令
        //Vue.directive('my-directive', myDirective);

        // 添加全局过滤器
        //Vue.filter('myFilter', myFilter);
    }
};
export default LoginPlugin;